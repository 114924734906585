import React from 'react';
import Seo from '../components/Seo';

export default function WorkPage() {
  return (
    <article className="workHolder">
      <Seo title="Work" />
      <h2>Work</h2>
      <h3>New projects </h3>
      <ul>
        <li>
          <a
            target="_blank"
            rel="noreferrer"
            href="https://www.panmacmillan.com/authors/harriet-gibsone/is-this-ok/9781035000999"
            title="Preorder 'Is This OK?' now">
            Preorder "Is This OK?" now
          </a>
        </li>
        <li>
          <a
            target="_blank"
            rel="noreferrer"
            href="https://www.thebookseller.com/rights/picador-lands-online-memoir-by-gibsone"
            title="Picador lands Harriet Gibsone's online memoir 'Is This OK?'">
            Picador lands Harriet Gibsone's memoir "Is This OK?"
          </a>
        </li>
      </ul>

      <h3>Ghostwriting</h3>
      <ul>
        <li>
          <a target="_blank" rel="noreferrer" href="https://www.theguardian.com/lifeandstyle/series/flashback" title="The Guardian: Flashback">
            The Guardian: Flashback
          </a>
        </li>
      </ul>

      <h3>Script writing</h3>
      <ul>
        <li>
          <a target="_blank" rel="noreferrer" href="https://www.youtube.com/watch?v=a7aLYIRPrj0" title="Aimee Lou Wood for Bumble">
            Aimee Lou Wood for Bumble
          </a>{' '}
        </li>
        <li>
          <a target="_blank" rel="noreferrer" href="https://www.bbc.co.uk/programmes/p08f6xzk" title="BBC Three's Behind the Filter">
            BBC Three's Behind the Filter
          </a>{' '}
        </li>
      </ul>

      <h3>Articles</h3>
      <ul>
        <li>
          <a
            target="_blank"
            rel="noreferrer"
            href="https://www.theguardian.com/lifeandstyle/2021/jun/19/i-was-a-31-year-old-newlywed-then-the-menopause-hit"
            title="The Guardian: 'I was a 31-year-old newlywed - and then the menopause hit'">
            The Guardian: "I was a 31-year-old newlywed - and then the menopause hit"
          </a>
        </li>
      </ul>
    </article>
  );
}
